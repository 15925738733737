import React, { useState, useEffect } from 'react';
import JSBI from 'jsbi';
const { TickMath, FullMath } = require('@uniswap/v3-sdk');

const baseToken = '0x5cCb63db1AF44E93095AF45aA820a2109DFecfE6'; // Blocksky
const quoteToken = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'; // WETH

async function main(
  baseToken,
  quoteToken,
  inputAmount,
  currentTick,
  baseTokenDecimals,
  quoteTokenDecimals
) {
  const sqrtRatioX96 = TickMath.getSqrtRatioAtTick(currentTick);
  const ratioX192 = JSBI.multiply(sqrtRatioX96, sqrtRatioX96);

  const baseAmount = JSBI.BigInt(inputAmount * (10 ** baseTokenDecimals));

  const shift = JSBI.leftShift(JSBI.BigInt(1), JSBI.BigInt(192));

  const quoteAmount = FullMath.mulDivRoundingUp(ratioX192, baseAmount, shift);
  console.log('quoteAmount', quoteAmount.toString() / (10 ** quoteTokenDecimals));

  return quoteAmount;
}

function App() {
  const [quoteAmount, setQuoteAmount] = useState(null);

  useEffect(() => {
    async function calculateQuoteAmount() {
      const result = await main(
        baseToken,
        quoteToken,
        1,
        -134798,
        18,
        18,
      );
      setQuoteAmount(result.toString() / (10 ** 18)); // Assuming quoteTokenDecimals is 18
    }

    calculateQuoteAmount();
  }, []);

  return (
    <div>
      <p>{quoteAmount}</p>
      {/* Render other parts of your app here */}
    </div>
  );
}

export default App;
